<template>
	<div id="add">
		<van-field v-model="name" label="名称" required placeholder="请输入名称"
			:rules="[{ required: true, message: '请填写名称' }]" />
		<van-field name="uploader" required label="菜单图片" :rules="[{ required: true, message: '请上传菜单图片' }]">
			<template #input>
				<van-uploader name="image" :before-delete="afterDeletes" :after-read="afterReads" multiple
					:max-count="1" v-model="image" />
			</template>
		</van-field>
		<div class="radio">
			<van-radio-group v-model="checked" direction="horizontal">
				<van-radio name="1">满折</van-radio>
				<van-radio name="2">满减</van-radio>
			</van-radio-group>
		</div>
		<van-field v-model="discount" label="折扣or满减" required placeholder="请输入折扣or满减"
			:rules="[{ required: true, message: '请填写折扣or满减' }]" />
		<van-field v-model="floor" label="下限" required placeholder="请输入下限"
			:rules="[{ required: true, message: '请填写下限' }]" />

		<van-field v-model="num" label="数量" required placeholder="请输入数量"
			:rules="[{ required: true, message: '请填写数量' }]" />

		<!-- <van-cell title="选择单个日期" :value="date" @click="show = true" />
			<van-calendar v-model:show="show" @confirm="onConfirm" />
 -->

		<!-- <van-field v-model="beginTime" label="开始时间" required placeholder="请输入开始时间例如:2021-04-01"
			:rules="[{ required: true, message: '请填写开始时间' }]" /> -->
		<van-cell is-link title="开始时间" :value="beginTime" @click="show = true" />
		<van-action-sheet v-model:show="show">
			<div class="content">
				<van-datetime-picker v-model="currentDate" type="datetime" title="选择完整时间" :min-date="minDate"
					:max-date="maxDate" @confirm="onconfirm" />
			</div>
		</van-action-sheet>
		<van-cell is-link title="结束时间" :value="overTime" @click="shows = true" />
		<van-action-sheet v-model:show="shows">
			<div class="content">
				<van-datetime-picker v-model="currentDates" type="datetime" title="选择完整时间" :min-date="minDates"
					:max-date="maxDates" @confirm="onconfirms" />
			</div>
		</van-action-sheet>
		

		<!-- <van-field v-model="overTime" label="结束时间" required placeholder="请输入结束时间例如:2021-04-08"
			:rules="[{ required: true, message: '请填写结束时间' }]" /> -->
		<div class="userRule">
			<p>使用规则</p>
			<textarea placeholder="使用规则" v-model="notedata"></textarea>
		</div>
		<div class="remarkBox">
			<p>备注&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
			<textarea placeholder="备注" v-model="remarkBox"></textarea>
		</div>
		<div style="margin: 16px;">
			<van-button round block type="primary" native-type="submit" @click="onSubmit">
				保存
			</van-button>
		</div>
	</div>
</template>

<script>
	import {
		uploadImage
	} from "@/api/common";
	import {
		ref,
		reactive
	} from 'vue';
	import {
		addCoupon
	} from '@/api/index'
	import {
		Toast
	} from "vant";
	export default {
		setup() {
			const name = ref('');
			const num = ref('');
			const fileList = ref([]);
			const checked = ref('1');
			const discount = ref('');
			const floor = ref('');
			const beginTime = ref('');
			const overTime = ref('');

			const show = ref(false);
			const actions = [{
					name: '选项一'
				},
				{
					name: '选项二'
				},
				{
					name: '选项三'
				},
			];
			const onSelect = (item) => {
				// 默认情况下点击选项时不会自动收起
				// 可以通过 close-on-click-action 属性开启自动收起
				show.value = false;
				Toast(item.name);
			};

			return {
				name, //名称
				fileList, //图片
				num, //数量
				checked, //满折or满减
				beginTime, //开始时间
				overTime, //结束时间
				discount, //折扣
				floor, //下限
				show,
				actions,
				onSelect,
			};
		},
		data() {
			return {
				notedata: '', //使用规则
				remarkBox: '', //备注
				from: {
					image: ''
				},
				image: [],
				dates: '',
				shows: false,
				minDate: new Date(1999, 0, 1),
				maxDate: new Date(2055, 10, 1),
				currentDate: new Date(),
				minDates: new Date(1999, 0, 1),
				maxDates: new Date(2055, 10, 1),
				currentDates: new Date(),
			}
		},
		methods: {
			onSelect(item) {
				// 默认情况下点击选项时不会自动收起
				// 可以通过 close-on-click-action 属性开启自动收起
				this.show = false;
			},
			onconfirm(value) {
				this.show = false;
				console.log(value)
				let date = new Date(value)
				let y = date.getFullYear()
				let m = date.getMonth() + 1
				m = m < 10 ? ('0' + m) : m
				let d = date.getDate()
				d = d < 10 ? ('0' + d) : d
				let h = date.getHours()
				h = h < 10 ? ('0' + h) : h
				let k = date.getMinutes()
				k = k < 10 ? ('0' + k) : k
				let s = date.getSeconds()
				s = s < 10 ? ('0' + s) : s
				const time = y + '-' + m + '-' + d +' '+ h + ':' + k + ':' + s
				this.beginTime = time
				console.log(time)
			},
			onconfirms(value) {
				this.shows = false;
				console.log(value)
				let date = new Date(value)
				let y = date.getFullYear()
				let m = date.getMonth() + 1
				m = m < 10 ? ('0' + m) : m
				let d = date.getDate()
				d = d < 10 ? ('0' + d) : d
				let h = date.getHours()
				h = h < 10 ? ('0' + h) : h
				let k = date.getMinutes()
				k = k < 10 ? ('0' + k) : k
				let s = date.getSeconds()
				s = s < 10 ? ('0' + s) : s
				const time = y + '-' + m + '-' + d +' '+ h + ':' + k + ':' + s
				this.overTime = time
				console.log(time)
			},
			afterDeletes(file, detail) {
				switch (detail.name) {
					case 'image':
						this.from.image = ''
						this.image.splice(detail.index, 1)
						break
				}
			},
			afterReads(file, detail) {
				const formData = new FormData();
				formData.append('file', file.file)
				uploadImage(formData).then((res) => {
					// console.log(res)
					if (detail.name === 'image') {
						this.from.image = res.data.path
					}
				})
			},

			onSubmit() {
				let params = {}
				params.name = this.name
				params.image = this.from.image
				params.type = this.checked
				params.rate = this.discount
				params.lower = this.floor
				params.count = this.num
				params.start_time = this.beginTime
				params.end_time = this.overTime
				params.detail = this.notedata
				params.remark = this.remarkBox
				addCoupon(params).then((res) => {
					// console.log(res)
					Toast.success(res.msg)
					this.$router.go(-1)
				})
			}
		},
		mounted() {

		},
		computed: {
			oneF() {
				return this.one.charAt(0);
			},
			oneL() {
				return this.one.slice(1, 3);
			}
		}
	}
</script>

<style lang="less" scoped>
	@import "~@/assets/style/preset.less";

	#add {
		background-color: #FFFFFF;

		.imgUpdate {
			display: flex;
			margin-left: 15px;
			margin-top: 10px;
			height: 88px;
			align-items: center;

			p {
				font-size: 14px;
				color: #646566;
			}

			.van-uploader {
				margin-left: 10px;
			}
		}

		.radio {
			margin: 10px 15px;
		}

		.userRule {
			display: flex;
			align-items: center;
			margin-left: 15px;
			margin-top: 20px;
			height: 120px;

			p {
				font-size: 14px;
				color: #646566;
			}

			textarea {
				width: 70%;
				height: 120px;
				border: solid 1px #ebedf0;
				margin-left: 10px;
				resize: none;
				padding: 10px;
			}
		}

		.remarkBox {
			display: flex;
			align-items: center;
			margin-left: 15px;
			margin-top: 30px;
			height: 120px;

			p {
				font-size: 14px;
				color: #646566;
			}

			textarea {
				width: 70%;
				height: 120px;
				border: solid 1px #ebedf0;
				margin-left: 10px;
				resize: none;
				padding: 10px;
			}
		}

		.btnBox {
			width: 100%;
			margin: 25px 0;
			display: flex;
			align-items: center;
			justify-content: center;

			.btn {
				width: 50%;
				height: 35px;
				background-color: #0000FF;
				border: none;
				border-radius: 17px;
				color: #FFFFFF;
			}
		}
	}

	textarea::-webkit-input-placeholder {
		font-size: 14px;
		color: #d2d4d6;
	}

	.content {
		padding: 16px 16px 160px;
	}
</style>
