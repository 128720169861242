import request from '@/utils/request'

// 登录方法
export function index() {
    return request({
        url: '/index',
        method: 'get',
        isLogin: false,
    })
}

// 登录方法
export function product(params) {
    return request({
        url: '/product/rec',
        method: 'get',
        params: params,
        isLogin: false,
    })
}

// 登录方法
export function about() {
    return request({
        url: '/about',
        method: 'get',
        isLogin: false,
    })
}
//获取首页图标列表
export function getIconList() {
    return request({
        url: '/trade/index',
        method: 'get',
        isLogin: false,
    })
}
//优惠劵列表
export function getShopList(params) {
    return request({
        url: '/coupon/lst',
        method: 'get',
        params: params,
    })
}
//优惠劵详情
export function getCouponDetail(params) {
    return request({
        url: '/coupon/detail',
        method: 'get',
        params: params,
    })
}

// 轮播图
export function lunbotu() {
    return request({
        url: '/slideshows',
        method: 'get',
    })
}
// 删除优惠券
export function couponDel(params) {
    return request({
        url: '/coupon/del',
        method: 'delete',
		params: params,
    })
}
//添加优惠券
export function addCoupon(params) {
    return request({
        url: '/coupon/add',
        method: 'post',
		params: params,
    })
}

// 图片上传
export function uploadImg(data) {
    return request({
        url: '/upload/image/one',
        method: 'post',
        data: data
    })
}


// 文章
export function articleList(params) {
    return request({
        url: '/articleList',
        method: 'get',
        params: params
    })
}

// 文章详情
export function articleDetail(params) {
    return request({
        url: '/articleDetail',
        method: 'get',
        params: params
    })
}

